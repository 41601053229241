import { Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Header = () =>  {
  const navigate = useNavigate();
  const navigateToRoute = (route: string) => {
    navigate(route, { replace: true});
  }
  return (

      
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand onClick={()=> navigateToRoute('/')}>
            <img
              alt="JSE Logo"
              src="https://jeevansangeetevents.com/images/jsimages/logo/logo2.jpg"
              width="150"
              height="80"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link active onClick={()=> navigateToRoute('/')}>Events</Nav.Link>
            <Nav.Link onClick={()=> navigateToRoute('/logout')}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default Header;