import { useQuery } from 'react-query'
import { fetchEventSummary } from '../../actions';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


const Events = () => {
    const navigate = useNavigate();
    const {data, isLoading }  = useQuery('eventSummary', fetchEventSummary);

    if(isLoading)   return <Spinner animation="border" />;
    const navigateToEventBookings = (eventId: number) => {
      navigate(`/event/${eventId}`);
    }
    return(
      <>
      <br/>
        <h2>All events</h2>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Event ID</th>
            <th>Name</th>
            <th>Type</th>
            <th>Is booking available</th>
            <th>Is upcoming event</th>
            <th>Event date</th>
            <th>View bookings</th>
          </tr>
        </thead>
        <tbody>
        {
          data?.map( ({event_id, event_date, event_status, event_type,event_name,booking_avail}, idx : number) => {
            return(
            <tr>
              <td>{idx + 1}</td>
              <td>{event_id}</td>
              <td>{event_name}</td>
              <td>{event_type}</td>
              <td>{booking_avail === 1 ? 'Yes' : 'No'}</td>
              <td>{event_status === 'upcoming' ? 'Yes' : 'No'}</td>
              <td>{event_date}</td>
              <td><Button variant="danger" size='sm' onClick={()=> navigateToEventBookings(event_id)}>View details</Button></td>
            </tr>
            );
          })
        }
        </tbody>
      </Table>
      </>
    )
}
export default Events;