import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { setUser, signInUser } from "../../actions";
import { useNavigate } from "react-router-dom";

const Login = () => {
const navigate = useNavigate();
const [username, setUsername] = useState<string>('');
const [password, setPassword] = useState<string>('');
const [processing, isProcessing] = useState<boolean>(false);
const [error, setError] = useState<string>('');

const handleSubmit = async(e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    isProcessing(true);
    const resp = await signInUser(username, password);
    if(resp.Message !== 'Error'){
        setError('')
        await setUser(username);
        navigate('/', { replace: true});
    }else{
        setError('Invalid username or passowrd')
    }
    isProcessing(false);
}
return(
 <div className="form-signin w-100 m-auto">
  <form>
    <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
      <label htmlFor="floatingInput">Username</label>
      <input type="text" value={username} onChange={e => setUsername(e.target.value)} className="form-control" id="floatingInput" placeholder="username"/>
      <br/>
      <label htmlFor="floatingPassword">Password</label>
      <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control" id="floatingPassword" placeholder="Password"/>
      <br/>
      <button className="w-100 btn btn-md btn-danger" onClick={e => handleSubmit(e)}>Sign in</button>
      {processing && <Spinner title="Signing in" animation="border" />}
      {error && <h5 className="errorText">{error}</h5>}
  </form>
</div>
    )
}
export default Login;