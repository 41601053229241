import { Booking, EventDetailsResponse, EventSummary, SigninResponse } from "../types";

export const fetchEventSummary = async () : Promise<EventSummary[]> => {
    return await fetch('https://apis.jeevansangeetevents.com/getAllEventSummary').then(d => d.json());
}

export const getBookingsForEvent = async(eventId: string): Promise<Booking[]> => {
    try{
        const resp = await fetch(`https://apis.jeevansangeetevents.com/getBookingsForEvent/${eventId}`);
        return resp.json();
    }catch(err){
        return [];
    }
}

export const getEventDetails = async(eventId: string): Promise<EventDetailsResponse | null> => {
    try{
        return fetch(`https://apis.jeevansangeetevents.com/getEventDetails/${eventId}`).then(d => d.json());
    }catch(err){
        return null;
    }
}

export const signInUser = async(userName: string, password: string): Promise<SigninResponse> => {
    try{
       return fetch("https://apis.jeevansangeetevents.com/signin", {
            method: "POST",
            body: JSON.stringify({
                userName,
                password,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then(response => response.json());
    }catch(err){
        return {
            Message: 'Error'
        };
    }
}

export const getEventWithBookings = async (eventId: string) => {
    if(!eventId) return null;
    const [bookings, eventDetails] = await Promise.all([
        getBookingsForEvent(eventId),
        getEventDetails(eventId)
    ])
    return {
        bookings,
        eventDetails
    }
}

export const setUser = async (userName: string) => {
    localStorage.setItem('userName', userName);
}
export const getUser = async () => {
    return localStorage.getItem('userName');
}