import './App.scss';
import {
  Route,
  Routes
} from "react-router-dom";
import Events from './containers/events/Events';
import Header from './components/header/Header';
import { ReactNode, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import EventDetails from './containers/eventDetails/EventDetails';
import Login from './containers/login/Login';
import { useNavigate } from "react-router-dom";
import { getUser } from './actions';
import { Spinner } from 'react-bootstrap';


const Layout = ({children}:{children : ReactNode}) => {
  return(
    <div className='appLayout'>
      <Container fluid>
        <Row>
          {children}
        </Row>
      </Container>
    </div>
  );
}

const RouteGuard = ({children}:{children : ReactNode}) => {
  const navigate = useNavigate();

  const [progress, showProgress] = useState<boolean>(true);
  useEffect(() => {
    const fetcher = async() => {
      showProgress(true);
      const username = await getUser();
      if(!username){
        navigate('login', {replace : true});
      }
      else{
        showProgress(false);
      }
    }
    fetcher();

  }, []);
  if(progress) return <Spinner animation="border" />;
  return(<>{children}</>)

}

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    navigate('login', {replace : true});
  }, [navigate]);
  return (<></>);
}

function App() {
  return (
    <>
    <Header />
    <Layout>
      <Routes >
        <Route path="/" element={ <RouteGuard><Events/></RouteGuard> } />
        <Route path="event/:eventId" element={ <RouteGuard><EventDetails/></RouteGuard> } />
        <Route path="login" element={ <Login/> } />
        <Route path="logout" element={ <Logout/> } />
      </Routes>
    </Layout>
    </>
      
  );
}

export default App;
