import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query'
import { getEventWithBookings } from "../../actions";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';

const EventDetails = () => {
    const navigate = useNavigate();
    const { eventId = '' } = useParams();
    const {data, isLoading }  = useQuery(['eventSummary',eventId], () => getEventWithBookings(eventId));
    const navigateToEventsList = () => navigate('/');
    
    if(isLoading)   return <Spinner animation="border" />;
    if(!data?.eventDetails?.TotalRecords) return(
    <div className="p-5 mb-4 bg-light rounded-3">
      <div className="container-fluid py-5">
        <h1 className="display-5 fw-bold">No such event</h1>
        <button className="btn btn-primary btn-lg" onClick={navigateToEventsList} type="button">Back to events list</button>
      </div>
    </div>
    );
    const {bookings, eventDetails} = data || {};
    const { Data } = eventDetails || {};
    const { event_name, event_date, event_time, event_venue, event_img ,event_type, event_status } = Data || {};
    return(
        <>
                <div className="container-fluid py-5">
                    <div className="eventDetails__eventInfo">
                        <img src={`https://jeevansangeetevents.com/${event_img}`} height={400} width={400}/>
                        <div className='eventInfoText'>
                            <h1 className="display-5 fw-bold">{event_name}</h1>
                            <p className="col-md-8 fs-4">Event date: <b>{event_date}</b></p>
                            <p className="col-md-8 fs-4">Event time: <b>{event_time}</b></p>
                            <p className="col-md-8 fs-4">Event venue: <b>{event_venue}</b></p>
                            <p className="col-md-8 fs-4">Is booking active: <b>{event_status}</b></p>
                            <p className="col-md-8 fs-4">Event type: <b>{event_type}</b></p>
                        </div>

                    </div>
                </div>
                <div className="container-fluid py-5">
                    <>
                        <button className="btn btn-primary btn-sm" onClick={navigateToEventsList} type="button">Back to events list</button>
                        <br/>
                        <br/>
                        <h2>Bookings:</h2>
                        <div className='table-responsive' >
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>Sr No</th>
                                <th>Order ID</th>
                                <th>Name</th>
                                <th>Seats booked</th>
                                <th>Phone No</th>
                                <th>Email Id</th>
                                <th>Address</th>
                                <th>Total amount</th>
                                <th>Payment status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {bookings && bookings.length > 0 && (

                                bookings.map( ({order_id,paymentstatus,address, booked_seats, emailid, phone_number,total_amount,username}, idx: number) => {
                                return(
                                <tr>
                                    <td>{idx + 1}</td>
                                    <td>{order_id}</td>
                                    <td>{username}</td>
                                    <td>{booked_seats}</td>
                                    <td>{phone_number}</td>
                                    <td>{emailid}</td>
                                    <td>{address}</td>
                                    <td>Rs {total_amount}</td>
                                    <td>{paymentstatus}</td>
                                </tr>
                                )
                            
                                })
                            )}
                            </tbody>
                        </Table>
                            </div> 
                    </>
                </div>
             
        </>
    )
}
export default EventDetails;